<!--配置管理-->
<template>
    <div class="mainBox">
        <!--Header-->
        <div class="handleBox flex flex-ai-c flex-jc-sb headerBox">
            <el-page-header content="基本配置管理 > "></el-page-header>
            <el-button type="primary" size="small" icon="el-icon-upload2" style="width:100px;" @click="submit">提交</el-button>
        </div>

        <!--form表单-->
        <div class="formBox shopBox">
            <!-- <el-tabs v-model="activeName" style="padding:0 20px;;box-sizing:border-box;">
                <el-tab-pane label="基本配置" name="first"></el-tab-pane>
                <el-tab-pane label="充值配置" name="second"></el-tab-pane>
                <el-tab-pane label="设置优惠券" name="third"></el-tab-pane>
            </el-tabs> -->
            <!--基本配置-->
            <div class="configMain" v-if="activeName == 'first' && list.length!=0">
                <div style="margin-bottom:50px">
                    <!--积分设置-->
                    <el-divider content-position="left">
                        <div class="mainColor">积分设置</div>
                    </el-divider>
					<el-row type="flex" align="middle" class="config-row">
                        <label for="">兑换抵用比例<i class="el-icon-warning-outline" @click="tipRule(1)"></i>
                        </label>
                        <el-input style="width:40%" v-model="list['integral_rate_rmb'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="设置积分比例" size="mini"></el-input>
                    </el-row>
                    <el-row type="flex" align="middle" class="config-row">
                        <label for="">预存款充值返积分<i class="el-icon-warning-outline" @click="tipRule(2)"></i>
                        </label>
                        <el-input style="width:40%" v-model="list['integral_prestore'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="设置积分比例" size="mini"></el-input>
                    </el-row>
                    <el-row type="flex" align="middle" class="config-row">
                        <label for="">签到返积分
                            <i class="el-icon-warning-outline" @click="tipRule(3)"></i>
                        </label>
                        <el-input style="width:40%" v-model="list['integral_sign'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="设置积分比例" size="mini"></el-input>
                    </el-row>

                    <!--提现设置-->
                    <el-divider content-position="left">
                        <div class="mainColor">提现设置</div>
                    </el-divider>
                    <el-row type="flex" align="middle" class="config-row">
                        <label for="">提现最低金额(元)
                            <i class="el-icon-warning-outline" @click="tipRule(4)"></i>
                        </label>
                        <el-input style="width:40%" v-model="list['withdraw_min'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="设置积分比例" size="mini"></el-input>
                    </el-row>
                    <el-row type="flex" align="middle" class="config-row">
                        <label for="">开始提现日期</label>
                        <el-input style="width:40%" v-model="list['withdraw_date_from'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="开始日期" size="mini"></el-input> 
                    </el-row>
                    <el-row type="flex" align="middle" class="config-row">
                        <label for="">结束提现日期</label>
                        <el-input style="width:40%" v-model="list['withdraw_date_to'].value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="结束日期" size="mini"></el-input>
                    </el-row>
                    
                    <!--续费提醒-->
					<el-divider content-position="left">
					    <div class="mainColor">续费提醒</div>
					</el-divider>
					<el-row type="flex" align="middle" class="config-row">
					    <label style="width:40px">提前</label>
					    <el-input style="width:10%" class="input" v-model="list['renew_remind'].value"  oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input>
					    天到期
					</el-row>
					<el-divider content-position="left">
					    <div class="mainColor">订单失效时间</div>
					</el-divider>
					<el-row type="flex" align="middle" class="config-row">
					    <label style="width:80px">未支付订单</label>
					    <el-input style="width:10%" class="input" v-model="list['order_miss'].value"  oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input>
						小时过期失效
					</el-row>
                </div>
            </div>
            

            <!--预存活动-->
          <!--  <div class="configMain" v-if="activeName == 'second'">
                
            </div> -->

            <!--设置优惠券-->
        <!--    <div class="configMain" v-if="activeName == 'third'">
                <el-divider content-position="left">
                    <div class="mainColor">新人优惠（所有商品）</div>
                </el-divider>
                <el-row type="flex" align="top" class="config-row">
                    <label style="width:100px;padding-top:16px">优惠券设置：</label>
                    <el-row style="width:calc(100% - 100px)">
                        <div v-for="(item,index) in formData.newCoupon" :key="index" style="margin-top:10px">
                            满
                            <el-input style="width:10%" class="input" v-model="item.value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input>
                            返
                            <el-input style="width:10%" class="input" v-model="item.value1" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input>
                            <el-radio-group v-model="item.radio" style="margin-right:20px">
                                <el-radio :label="1">使用</el-radio>
                                <el-radio :label="2">停用</el-radio>
                            </el-radio-group>
                            <el-button type="success" v-if="index==0" icon="el-icon-plus" style="padding:8px" @click="addCoupon('newCoupon')" circle></el-button>
                            <el-button type="danger" v-else icon="el-icon-minus" style="padding:8px" @click="deleteCoupon('newCoupon',index)" circle></el-button>
                        </div>
                    </el-row>
                    
                </el-row>
                <el-divider content-position="left">
                    <div class="mainColor">会员购物（所有商品）</div>
                </el-divider>
                
                <el-row type="flex" align="top" class="config-row">
                    <label style="width:100px;padding-top:16px">优惠券设置：</label>
                    <el-row style="width:calc(100% - 100px)">
                        <div v-for="(item,index) in formData.coupon" :key="index" style="margin-top:10px">
                            满
                            <el-input style="width:10%" class="input" v-model="item.value" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input>
                            返
                            <el-input style="width:10%" class="input" v-model="item.value1" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="mini"></el-input>
                            <el-radio-group v-model="item.radio" style="margin-right:20px">
                                <el-radio :label="1">使用</el-radio>
                                <el-radio :label="2">停用</el-radio>
                            </el-radio-group>
                            <el-button type="success" v-if="index==0" icon="el-icon-plus" style="padding:8px" @click="addCoupon('coupon')" circle></el-button>
                            <el-button type="danger" v-else icon="el-icon-minus" style="padding:8px" @click="deleteCoupon('coupon',index)" circle></el-button>
                        </div>
                    </el-row>
                </el-row>
            </div> -->
        </div>

        <!--使用规则-->
        <el-dialog :title="title" :visible.sync="dialogFormVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="550px">
            <div v-html="tipContent" style="line-height:2"></div>
        </el-dialog>
    </div>
</template>

<script>
    import {configAllDataApi,configSaveDataApi}  from '@/api/config.js';
    export default {
        data() {
            return {
                activeName:'first',
                title:'',
                tipContent:'',
                list:[],
                
                formData:{
                    newCoupon:[{
                        radio:1
                    }],
                    coupon:[{
                        radio:1
                    }],
                },

                dialogFormVisible:false
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            getData: function(){
                configAllDataApi().then(response=>{
                    this.list = response.list;
                })
            },
            
            tipRule: function(num){
                this.dialogFormVisible = true;
                if (num == 1) {
                    this.title = '积分抵用规则';
                    this.tipContent = '积分使用：' + this.list['integral_rate_rmb'].value 
					// this.tipContent = '购物返积分比例：1表示积分按照1:1比例返还，即购物金额100元返100个积分'
                }else if(num == 2){
                    this.title = '积分预存款充值返回规则';
                    this.tipContent = '预存款充值返积分比例：表示预存款充值返回积分' + this.list['integral_prestore'].value + ''
                } else if(num == 3){
                    this.title = '签到返积分规则';
                    this.tipContent = '签到一次返回' + this.list['integral_sign'].value + '积分'
                } else if(num == 4){
                    this.title = '提现规则';
                    this.tipContent = '最低提现金额需大于' + this.list['withdraw_min'].value + '元'
                }
            },
            // //添加新人优惠
            // addCoupon: function(selectCoupon){
            //     let idx = this.formData[selectCoupon].length - 1;
            //     let arr = {
            //         value:'',
            //         value1:'',
            //         radio:1
            //     }
            //     if (this.formData[selectCoupon].length == 0) {
            //         this.formData[selectCoupon].push(arr);
            //     }else{
            //         if (!this.formData[selectCoupon][idx].value || !this.formData[selectCoupon][idx].value1) {
            //             this.$message({
            //                 message: selectCoupon == 'newCoupon' ? '新人优惠券金额不能有空！' : '会员购物商品优惠券金额不能有空',
            //                 type: 'error'
            //             })
            //             return;
            //         } else {
            //             this.formData[selectCoupon].push(arr);
            //         }
            //     }
            // },
            // //删除新人优惠
            // deleteCoupon:function(selectCoupon,index){
            //     this.formData[selectCoupon].splice(index,1);
            // },
            //设置保存
            submit: function(){
                switch (this.activeName) {
                    case 'first':
                        configSaveDataApi(this.list).then(()=>{
                            this.getData();
                            this.$message({
                                type: 'success',
                                message: '已设置!'
                            });
                        })
                        break;
                
                    default:
                        break;
                }
            },
        },
    }
</script>